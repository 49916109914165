/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import dayjs from 'dayjs';
import Datepicker from 'react-datepicker';
import parse from 'html-react-parser'

// Elements
import { LabelCSS, InputCSS, TextareaCSS } from 'components/elements/Form'
import ParseContent from 'components/shared/ParseContent'

// Libraries
import { ErrorMessage } from 'formik'
import Select from 'react-select'

const StyledInput = styled.input`
  ${InputCSS};
`

const StyledDatepicker = styled(Datepicker)`
  ${InputCSS};
`

const StyledTextArea = styled.textarea`
  ${TextareaCSS};
`

const OptionCSS = css`
  font-size: ${props => props.theme.font.size.m};
  font-weight: ${props => props.theme.font.weight.s};
  display: flex;
  align-items: center;
  justify-content: start;
  padding-right: 10px;

  & > span {
    text-align: left;
    text-decoration: ${props => props.isActive ? 'underline' : 'none'};
  }
`

const StyledLabel = styled.label`
  color: ${props => props.theme.color.text.grey};
  font-weight: ${props => props.theme.font.weight.s};
`

const Option = styled.div`
  ${OptionCSS};
  color: ${props => props.theme.color.text.main};
`

const LabelNumber = styled.p`
  font-size: ${props => props.theme.font.size.xm};
  color: ${props => props.theme.color.text.contrast};
  font-weight: ${props => props.theme.font.weight['700']};
`

export const Checkbox = styled.div`
  border: 1px solid ${props => props.theme.color.background};
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`

export const Check = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  height: 13px;
  width: 7px;
  border-bottom: 3px solid ${props => props.theme.color.secondary};
  border-right: 3px solid ${props => props.theme.color.secondary};
`

export const Error = styled(ErrorMessage)`
  background-color: ${props => props.theme.color.contrast};
  color: ${props => props.theme.color.text.light};
  font-size: ${props => props.theme.font.size.sm};
  margin: 0 auto;
  width: 50%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
`

const Input = ({ item, data, setData, setFieldValue, isCustom, fullWidth }) => {
  let value = '';

  if (data[`input_${item.id}`]) {
    value = data[`input_${item.id}`]
  }

  if (item.type === 'html') {
    return (
      <div className="my-5 d-flex justify-content-center">
        {parse(item.content)}
      </div>
    )
  }

  if (item.type === 'select') {

    // Populate a options array
    const options = []

    JSON.parse(item.choices).forEach(choice => {
      options.push({
        value: choice.value,
        label: choice.text,
        type: 'select',
        name: `input_${item.id}`
      })
    })

    return (
      <div className={`field_${item.id} d-flex flex-wrap align-items-center mb-4`}>
        <div className="col-lg-5 d-flex">
          <StyledLabel htmlFor={`input_${item.id}`}>{item.label}</StyledLabel>
        </div>
        <div className="col-lg-7 d-flex">
          <div className="w-100">
            <Select
              options={options}
              onChange={(e) => {
                const event = { target: { name: `input_${item.id}`, value: e.value } }

                setData(event)
                setFieldValue(event.target.name, event.target.value)
              }}
              value={options.filter(option => option.value === value)}
              placeholder={`${value !== '' ? value : item.placeholder}`}
              classNamePrefix="react-select"
            />
          </div>
        </div>
      </div>
    )
  }

  if (item.type === 'multiselect') {
    const options = JSON.parse(item.choices)

    return (
      <div className={`field_${item.id} d-flex flex-wrap align-items-center mb-4`}>
        <div className="col-lg-5 d-flex">
          <StyledLabel htmlFor={`input_${item.id}`}>
            {item.label}
            {item.isRequired && (
              <span>
                *
              </span>
            )}
          </StyledLabel>
        </div>
        <div className="col-lg-7 d-flex">
          <div className="d-flex align-items-center justify-content-between flex-wrap mt-3 w-100">
            {options.map(({ text, value: optionValue }, index) => {
              const isActive = value === optionValue

              return (
                <Option
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${item.id}_${index}`}
                  role="button"
                  onClick={() => {
                    const event = { target: { name: `input_${item.id}`, value: optionValue } }

                    setData(event)
                    setFieldValue(event.target.name, event.target.value)
                  }}
                  isActive={isActive}
                  className="mb-2 w-50"
                >
                  <Checkbox
                    className="mr-3"
                  >
                    {isActive && (
                      <Check />
                    )}
                  </Checkbox>
                  <span>
                    {text}
                  </span>
                </Option>
              )
            })}
          </div>
        </div>
        <Error
          name={`input_${item.id}`}
          component="div"
          className="py-1 px-3"
        />
      </div>
    )
  }


  if (item.type === 'date') {
    const minDate = dayjs().toDate();

    return (
      <div className={`field_${item.id} d-flex flex-wrap align-items-center mb-4`}>
        <div className="col-lg-5 d-flex">
          <StyledLabel htmlFor={`input_${item.id}`}>
            {item.label}
            {item.isRequired && (
              <span>
                *
              </span>
            )}
          </StyledLabel>
        </div>

        <div className="col-lg-7 d-flex">
          <StyledDatepicker
            dateFormat="dd/MM/yyyy"
            selected={dayjs(value).isValid() ? dayjs(value).toDate() : null}
            onChange={newValue => {
              if (!newValue) {
                const event = { target: { value: null, type: item.type, name: `input_${item.id}` } }
                setData(event)
                setFieldValue(`input_${item.id}`, null)
                return;
              }

              const date = dayjs(newValue).format('YYYY-MM-DD');
              const event = { target: { value: date, type: item.type, name: `input_${item.id}` } }

              setData(event)
              setFieldValue(`input_${item.id}`, date)
            }}
            placeholderText="dd-mm-yyyy"
            onChangeRaw={(event) => { event.preventDefault() }}
          />

          <Error
            name={`input_${item.id}`}
            component="div"
            className="py-1 px-3"
          />
        </div>
      </div>
    )
  }

  if (item.type === 'textarea') {
    return (
      <div className={`field_${item.id} d-flex flex-wrap align-items-center justify-space-between mb-4`}>
        <div className="col-lg-5 d-flex">
          <StyledLabel htmlFor={`input_${item.id}`}>{item.label}</StyledLabel>
        </div>
        <div className="col-lg-7  d-flex">
          <StyledTextArea
            id={`input_${item.id}`}
            value={value}
            type={item.type}
            onChange={event => {
              setData(event)
              setFieldValue(event.target.name, event.target.value)
            }}
            name={`input_${item.id}`}
          />
          <Error
            name={`input_${item.id}`}
            component="div"
            className="py-1 px-3"
          />
        </div>
      </div>
    )
  }


  return (
    <div className={`field_${item.id} d-flex flex-wrap align-items-center justify-space-between mb-4`}>
      <div className="col-lg-5 d-flex">
        <StyledLabel htmlFor={`input_${item.id}`}>
          {item.label}
          {item.isRequired && (
            <span>
              *
            </span>
          )}
        </StyledLabel>
      </div>
      <div className="col-lg-7  d-flex">
        <StyledInput
          id={`input_${item.id}`}
          value={value}
          type={item.type}
          onChange={event => {
            setData(event)
            setFieldValue(event.target.name, event.target.value)
          }}
          name={`input_${item.id}`}
          placeholder={item.placeholder}
        />
        <Error
          name={`input_${item.id}`}
          component="div"
          className="py-1 px-3"
        />
      </div>
    </div>
  )
}

export default Input